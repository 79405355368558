import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Home, Update_Home } from '../../Actions/home.action';
import { ChoixLangue } from '../../Components/Context/LangueContext';
import Navbar from '../../Components/Navbar/Navbar';


import "../Home/Home.css";

const PrivateHome = () => {
    const dispatch = useDispatch();
    const HomeDB = useSelector((state) => state.homeReducer);
    const { LANGUE } = useContext(ChoixLangue)

    const [HomeLienFilmFond, setHomeLienFilmFond] = useState("");
    const [GrosTitre1, setGrosTitre1] = useState("");
    const [GrosTitre2, setGrosTitre2] = useState("");
    const [PetitTitre1, setPetitTitre1] = useState("");
    const [PetitTitre2, setPetitTitre2] = useState("");
    const [PetitTitre3, setPetitTitre3] = useState("");

    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    const types = ['video/mp4'];

    const handleChange = (e) => {
        let selected = e.target.files[0];

        if (selected && types.includes(selected.type)) {
            setFile(selected);
            setError('');
        } else {
            setFile(null);
            setError("Selectionner un fichier .mp4 s'il vous plaît !! ");
        }
    };


    useEffect(() => {

        setHomeLienFilmFond(HomeDB[LANGUE].HomeLienFilmFond);
        setGrosTitre1(HomeDB[LANGUE].GrosTitre1);
        setGrosTitre2(HomeDB[LANGUE].GrosTitre2);
        setPetitTitre1(HomeDB[LANGUE].PetitTitre1);
        setPetitTitre2(HomeDB[LANGUE].PetitTitre2);
        setPetitTitre3(HomeDB[LANGUE].PetitTitre3);

        dispatch(Get_Home());


    }, [LANGUE]);

    //UPDATE DE LA BASE DE DONNEES 
    const updateBD = () => {
        const updateDB = {
            GrosTitre1,
            GrosTitre2,
            // HomeLienFilmFond: "https://player.vimeo.com/video/" + HomeLienFilmFond + "?background=1&autoplay=1&loop=1&byline=0&title=0",
            HomeLienFilmFond,
            PetitTitre1,
            PetitTitre2,
            PetitTitre3,
            id: LANGUE,
        };

        dispatch(Update_Home(updateDB, LANGUE));
    }


    return (

        <div className="global-container">
            <Navbar />
            <div className="form">
                <label>
                    <input type="file" onChange={handleChange} />

                </label>
                <div className="output">
                    {error && <div className="error">{error}</div>}
                    {/* {file && <div>{file.name}</div>} */}
                    {/* {file && <ProgressBar file={file} setFile={setFile} />} */}
                </div>
                <input className="inputDB"
                    type="text"
                    placeholder="Agence Marketing"
                    value={GrosTitre1}
                    onChange={(e) => setGrosTitre1(e.target.value)}
                />
                <input className="inputDB"
                    type="text"
                    placeholder="Digital"
                    value={GrosTitre2}
                    onChange={(e) => setGrosTitre2(e.target.value)}
                />

                <input className="inputDB"
                    type="text"
                    placeholder="Petit Titre 1"
                    value={PetitTitre1}
                    onChange={(e) => setPetitTitre1(e.target.value)}
                />

                <input className="inputDB"
                    type="text"
                    placeholder="Petit Titre 2"
                    value={PetitTitre2}
                    onChange={(e) => setPetitTitre2(e.target.value)}
                />

                <input className="inputDB"
                    type="text"
                    placeholder="Petit Titre 3"
                    value={PetitTitre3}
                    onChange={(e) => setPetitTitre3(e.target.value)}
                />

                <button onClick={updateBD} >Mise à jour du site</button>
            </div>
        </div>


    );

};

export default PrivateHome;

