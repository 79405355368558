import { GET_HOME, UPDATE_HOME } from "../Actions/home.action";

const initialState = {};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_HOME:
            return action.payload;

        case UPDATE_HOME:
            return state.map((olddata) => {
                if (olddata.id === action.payload.id) {
                    return action.payload
                } else return olddata;
            });

        default:
            return state;

    }
}

