import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Get_Home } from '../../Actions/home.action';
import { AuthContext } from './context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const { CurrentUser } = useContext(AuthContext)

    return (
        <Route
            {...rest}
            render={() => {

                return CurrentUser ? <Component /> : <Redirect to="/" />
            }}
        >

        </Route>
    )
};

export default PrivateRoute;

