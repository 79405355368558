import React from 'react';
import SignIn from '../../Components/AuthForm/SignIn';
import SignUp from '../../Components/AuthForm/SignUp';
import Navbar from '../../Components/Navbar/Navbar';
import "./Home.css";

const Home = () => {
    return (
        <div className="global-container">
            <Navbar />
            <div className="content-container">
                <h1>Administration du site de l'Agence Bengale</h1>
                <p>Veuillez vous connecter pour accéder aux paramètres</p>
            </div>
            <SignUp />
            <SignIn />


        </div>
    );
};

export default Home;