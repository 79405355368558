import firebase from "firebase";
import "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyADpgq30Mk3D9p98AjEDYVa-WIifMzYJuY",
    authDomain: "admin-bengale.firebaseapp.com",

    projectId: "admin-bengale",
    storageBucket: "admin-bengale.appspot.com",
    messagingSenderId: "1014328975434",
    appId: "1:1014328975434:web:4b054c4cdf57ddf87046b3",
    measurementId: "G-CRP7SZKH2L"
})


export const auth = app.auth();
export default app;

