import { createContext, useState } from "react";

export const ChoixLangue = createContext();

const ChoixLangueProvider = props => {

    const [LANGUE, setLANGUE] = useState(0)

    const toggleLangue = (changeLangue) => {
        setLANGUE(changeLangue);
    }


    return (
        <ChoixLangue.Provider value={{ LANGUE, toggleLangue }}>
            {props.children}
        </ChoixLangue.Provider>
    )
}

export default ChoixLangueProvider;
