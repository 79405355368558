import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

//Redux
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./Reducers";
import { Get_Home } from "./Actions/home.action";


const store = createStore(
  rootReducer, //Permet de lire le fichier index.js pour créer plusieur reducer
  composeWithDevTools(applyMiddleware(thunk)))

store.dispatch(Get_Home());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
