import React, { useContext } from 'react';
import { AuthContext } from '../AuthForm/context/AuthContext';
import { ChoixLangue } from '../Context/LangueContext';
import { useDispatch } from 'react-redux'

import "./Navbar.css";



const Navbar = () => {

    const dispatch = useDispatch()

    const toggleSignIn = () => {
        dispatch({
            type: "TOGGLEIN"
        })
    }

    const toggleSignUp = () => {
        dispatch({
            type: "TOGGLEUP"
        })
    }

    const { CurrentUser } = useContext(AuthContext)
    const { logout } = useContext(AuthContext)

    const { toggleLangue } = useContext(ChoixLangue)



    return (
        <div className="navbar">
            <div className="logo">
                <img src="./img/logo.png" alt="logo" />
            </div>
            <nav className={CurrentUser ? "navbar-site" : "hide-navbar-site"}>
                <button>Accueil</button>
                <button>Expertise</button>
                <button>Productions</button>
                <button>Contact</button>

            </nav>
            <nav className={!CurrentUser ? "login" : "hide-login"}>
                <button onClick={toggleSignIn}>Connexion</button>
                {/* <button onClick={toggleSignUp}>Inscription</button> */}
            </nav>
            <nav className={CurrentUser ? "login" : "hide-login"}>
                <button onClick={logout}>Déconnexion</button>
                <button onClick={() => toggleLangue(0)}>FR</button>
                <button onClick={() => toggleLangue(1)}>EN</button>
            </nav>
        </div>
    );
};

export default Navbar;