import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./Components/AuthForm/context/AuthContext";
import PrivateHome from "./Pages/PrivatePages/PrivateHome";
import Home from "./Pages/Home/Home";
import PrivateRoute from "./Components/AuthForm/PrivateRoute";
import ChoixLangueProvider from "./Components/Context/LangueContext";

function App() {

  return (

    <AuthProvider>
      <ChoixLangueProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute exact path="/HomePrivate" component={PrivateHome} />
          </Switch>
        </Router>
      </ChoixLangueProvider>
    </AuthProvider>

  );
}

export default App;
