import firebase from '../Utlitaires/Firebase';

export const GET_HOME = "GET_HOME";
export const UPDATE_HOME = "UPDATE_HOME";


export const Get_Home = () => {
    return (dispatch) => {

        const LireHomeBD = firebase.database().ref("Home");

        const list = [];
        LireHomeBD.on("value", (snapshot) => {
            let prelist = snapshot.val();
            dispatch({ type: GET_HOME, payload: prelist })
        });
    }
}


export const Update_Home = (data, lang) => {
    return (dispatch) => {
        const Home_BD = firebase.database().ref("Home").child(lang);

        Home_BD.update(data);

        dispatch({ type: UPDATE_HOME, payload: { ...data } })
    }
};


